<template>
  <v-row>
    <v-col cols="12" class="text-h5">
      <v-row no-gutters justify="space-between">
        <div class="text-start">Narudzbenica</div>
        <div class="text-end">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-col>
    <v-col cols="5" align="start">
      <v-text-field label="Broj Narudzbenice" hide-details filled dense class="pb-4"></v-text-field>
      <v-text-field label="Datum" hide-details filled dense class="pb-4"></v-text-field>
      <v-text-field label="Narucio" hide-details filled dense class="pb-4"></v-text-field>
      <v-textarea label="Napomena" hide-details filled dense></v-textarea>
    </v-col>
    <v-col cols="2"></v-col>
    <v-col cols="5" align="end">
      <v-text-field label="Dobavljac" hide-details filled dense class="pb-4"></v-text-field>
      <p class="text-start">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        Lorem Ipsum has been the industry's standard.
      </p>
    </v-col>
    <v-col cols="12">
      <v-btn icon class="mt-2" @click="dialog = true">
        <v-icon>mdi-plus-box-outline</v-icon>
      </v-btn>
      <v-simple-table style="background-color: transparent">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Naziv
            </th>
            <th class="text-left">
              Kolicina
            </th>
            <th class="text-left">
              Merna Jedinica
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in items"
            :key="item.name"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.order }}</td>
            <td>{{ item.measure }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>

import axios from "../../../plugins/axios";

export default {
  props: {
    items: {
      type: Array
    },
    warehouse: {
      type: Number
    }
  },
  data() {
    return {
      loading: false
    }
  }
}

</script>
