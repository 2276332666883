<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>
        Trasakcije
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details        c
          clearable
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="transactions"
        :search="search"
      >
        <template v-slot:item.transaction_type="{item}">
          <td :style="{backgroundColor: item.transaction_type === 'entry' ? '#86df86' : 'yellow'}">{{ item.transaction_type }}</td>
        </template>
        <template v-slot:item.created_at="{item}">
          {{ moment(item.created_at).format('DD-MM-YYYY HH:mm') }}
        </template>
      </v-data-table>
    </v-card>

  </v-container>
</template>

<script>

import moment from "moment";
import axios from "../../plugins/axios";

export default {
  props: {
    selectedGrocery: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment: moment,
      transactions: [],
      _transactions: [],
      search: '',
      headers: [
        {
          text: 'Br. Dokumenta',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Magacin',
          align: 'start',
          value: 'warehouse.name',
        },
        { text: 'Sifra', value: 'grocery.code' },
        { text: 'Proizvod', value: 'grocery.name' },
        { text: 'Transakcija', value: 'transaction_type' },
        { text: 'Kolicina', value: 'amount' },
        { text: 'Merna jedinca', value: 'grocery.measure' },
        { text: 'Datum', value: 'created_at' }
      ],
    }
  },
  created() {
    axios.get('/warehouse-transaction').then(res => {
      this.transactions = res.data
      this._transactions = res.data
    })

    this.search = this.selectedGrocery.grocery.name
  }
}


</script>
