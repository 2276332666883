<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>
        Trebovanje
        <v-spacer></v-spacer>

        <v-autocomplete
          label="Magacin" :items="warehouses" v-model="editedItem.warehouse"
          hide-details item-text="name" item-value="id"
          filled dense
          class="mr-1"
        >
        </v-autocomplete>

        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_from"
              label="Datum OD"
              readonly
              :disabled="!editedItem.warehouse"
              hide-details
              filled
              dense
              class="mr-1"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_from"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_to"
              label="Datum DO"
              readonly
              :disabled="!editedItem.warehouse"
              hide-details
              filled
              dense
              class="mr-1"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_to"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          hide-details        c
          clearable
          class="mx-2"
          filled
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="editedItem.groceries"
        :headers="headers"
        :items="items"
        :search="search"
        item-key="name"
        show-select
        :loading="loading"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:item.stock="{item}">
          <td :style="{backgroundColor: item.stock < item.quantity ? 'yellow' : '#86df86'}">{{item.stock}}</td>
        </template>
        <template v-slot:item.ordered="{item}">
          {{item.ordered.toFixed(3)}}
        </template>
        <template v-slot:item.order="{item}">
          <td>
            <v-text-field hide-details solo v-model="item.order" dense type="number"></v-text-field>
          </td>
        </template>
        <template v-slot:item.created_at="{item}">
          {{ moment(item.created_at).format('DD-MM-YYYY HH:mm') }}
        </template>
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined class="px-2" @click="dialogAction = true; actionOrder = true; actionSell = false">Naruci</v-btn>
        <v-btn color="primary" class="px-5" @click="dialogAction = true; actionSell = true; actionOrder = false">Izdaj</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogAction" max-width="850" scrollable>
      <invoice-template
        v-if="actionOrder"
        @close="actionSell = false; dialogAction = false"
        :item="editedItem"
        @save="save('order')"
        transaction-type="order"
        warehouse-disabled
      >
      </invoice-template>

      <invoice-template
        v-if="actionSell"
        @close="actionSell = false; dialogAction = false"
        :item="editedItem"
        @save="save('exit')"
        warehouse-disabled
        transaction-type="exit"
      >
      </invoice-template>

    </v-dialog>
  </v-container>
</template>

<script>

import axios from "../../plugins/axios";
import orderDialog from './requirement/order'
import sellDialog from './requirement/sell'
import InvoiceTemplate from "./invoice-template";
import moment from "moment";

export default {
  components: {InvoiceTemplate, orderDialog, sellDialog},
  data() {
    return {
      dialogAction: false,
      actionOrder: false,
      actionSell: false,
      loading: false,
      date_from: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      //selected: [],
      editedItem: {
        dispatched_by: null,
        received_by: null,
        notes: null,
        warehouse: null,
        document_type: 'exit',
        document_date: moment().format('YYYY-MM-DD'),
        groceries: []
      },
      items: [],
      search: '',
      headers: [
        {
          text: 'Naziv',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Sifra',
          align: 'start',
          value: 'code',
        },
        {
          text: 'Potrebna Kolicina',
          align: 'start',
          value: 'quantity',
        },
        {
          text: 'Trenutna Kolicina',
          align: 'start',
          value: 'stock',
        },
        {
          text: 'Naruceno',
          align: 'start',
          value: 'order',
          sortable: false
        },
        {
          text: 'Izdato',
          align: 'start',
          value: 'ordered',
        },
        { text: 'Jedinica Mere', value: 'measure' }
      ],
      warehouses: [],
      warehouse: ''
    }
  },
  created() {
    axios.get('/warehouse-new').then(res => {
      this.warehouses = res.data
    })
  },
  methods: {
    load() {
      this.loading = true
      axios.get(`/reports/warehouse-new/daily/restaurant/${this.restaurant.id}?date_from=${this.date_from}&date_to=${this.date_to}&warehouse_id=${this.editedItem.warehouse}`)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    save(transaction_type) {
      this.loading = true

      this.editedItem.document_type = transaction_type
      this.editedItem.warehouse_id = this.editedItem.warehouse
      this.editedItem.groceries = this.editedItem.groceries.map(grocery => {
        grocery.warehouse_id = this.editedItem.warehouse
        grocery.grocery_id = grocery.id
        grocery.transaction_type = transaction_type
        grocery.quantity = grocery.order
        return grocery
      })
      axios.post('/warehouse-transaction/store-more', this.editedItem)
        .finally(() => {
          this.actionSell = false
          this.dialogAction = false
          this.loading = false
        })
    }
  },
  computed: {
    restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    }
  },
  watch: {
    date_from() {
      this.load()
    },
    date_to() {
      this.load()
    }
  }
}

</script>
