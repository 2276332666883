<template>
    <v-card>
      <v-card-title>
        {{transactionType === 'exit' ? 'Otpremnica' : transactionType === 'entry' ? 'Ulaz' : 'Narudzbenica'}}
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon @click="$emit('close')">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 600px;">
        <v-sheet color="#fffded" class="a4-page">
          <v-row>
            <v-col cols="12" class="text-h6 font-weight-bold pb-0">
              <v-autocomplete label="Magacin" v-model="editedItem.warehouse" :items="warehouses" item-text="name" item-value="id" return-object filled :rules="nameRules" required :disabled="warehouseDisabled || disabled"></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-form
                ref="form"
                v-model="valid"
                lazy-validations
              >
                <v-row no-gutters>
                  <v-col cols="5" align="start">
                    <input type="date" v-model="editedItem.document_date" class="mb-3" :rules="nameRules" :disabled="disabled" required/>
                    <template v-if="['exit', 'entry'].includes(transactionType)">
                      <v-autocomplete v-model="editedItem.dispatched_by" :items="users" item-text="fullName" item-value="id" label="Robu izdao" filled :rules="nameRules" required :disabled="disabled"></v-autocomplete>
                      <v-autocomplete v-model="editedItem.received_by" :items="users" item-text="fullName" item-value="id" label="Robu preuzeo" filled :rules="nameRules" required :disabled="disabled"></v-autocomplete>
                    </template>
                    <template v-if="transactionType === 'order'">
                      <v-autocomplete v-model="editedItem.ordered_by" :items="users" item-text="fullName" item-value="id" label="Robu narucio" filled :rules="nameRules" required :disabled="disabled"></v-autocomplete>
                    </template>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col cols="6" align="end">
                    <v-textarea label="Detalji" v-model="editedItem.notes" hide-details filled rows="5" class="mt-8" :disabled="disabled"></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-btn icon class="mt-15" @click="dialog2 = true" :disabled="disabled">
                      <v-icon>mdi-plus-box-outline</v-icon>
                    </v-btn>
                    <v-simple-table style="background-color: transparent">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">
                            Naziv
                          </th>
                          <th class="text-left">
                            Kolicina
                          </th>
                          <th class="text-left">
                            Merna Jedinica
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="item in editedItem.groceries"
                          :key="item.name"
                        >
                          <td>{{ item.name }}</td>
                          <td>{{ item.order || item.quantity }}</td>
                          <td>{{ item.measure }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-alert type="warning" v-if="!validate" outlined>
                      Izaberite Namirnice
                    </v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>
      <v-card-actions v-if="!disabled">
        <v-btn color="primary" block @click="save">Snimi</v-btn>
      </v-card-actions>


      <v-dialog v-model="dialog2" max-width="600">
        <v-card style="position:sticky; top: 10px">
          <v-card-title>
            Izdavanje iz magacina
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog2 = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-if="editedItem && editedItem.warehouse && editedItem.warehouse.id"
                  label="Izberi Stavku"
                  :items="groceries.filter(i => i.warehouse_id === editedItem.warehouse.id)"
                  v-model="grocery"
                  item-value="id"
                  item-text="grocery.name"
                  return-object
                  hide-details
                  filled
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Stanje u Magacinu"
                  hide-details
                  filled
                  dense
                  :value="grocery ? grocery.quantity : 0"
                  disabled
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Kolicina"
                  filled
                  dense
                  v-model="quantity"
                  :suffix="grocery ? grocery.measure : ''"
                  :max="grocery ? grocery.quantity : 0"
                  :hint="'Kolicina mora biti manja od ' + (grocery ? grocery.quantity : 0)"
                  type="number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  height="52"
                  tile
                  @click="editedItem.groceries.push({
                    id: grocery.id,
                    grocery_id: grocery.grocery.id,
                    name: grocery.grocery.name,
                    measure: grocery.measure,
                    quantity: quantity,
                    warehouse_id: grocery.warehouse_id,
                    transaction_type: 'exit'
                  });
                  dialog2 = false; validate = true"
                  :disabled="!grocery || quantity <= 0"
                  block
                  :loading="loading"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
</template>

<script>

import axios from "../../plugins/axios";

export default {
  props: {
    item: {
      type: Object
    },
    warehouseDisabled: {
      default: false
    },
    transactionType: {
      default: 'exit'
    },
    disabled: {
      default: false
    }
  },
  data() {
    return {
      valid: true,
      nameRules: [
        v => !!v || 'Polje je obavezno'
      ],
      editedItem: {},
      dialog2: false,
      grocery: null,
      groceries: [],
      validate: true,
      warehouses: [],
      warehouse: '',
      users: [],
      quantity: 0,
      loading: false
    }
  },
  created() {
    this.editedItem = this.item

    axios.get("/inventory").then((res) => {
      this.groceries = res.data;
    });

    axios.get("/warehouse-new").then((res) => {
      this.warehouses = res.data;
    });

    axios.get(`/restaurants/${this.restaurant.id}/users`)
      .then(res => {
        this.users = (res.data.users || []).map(item => ({
          ...item,
          fullName: `${item.name} ${item.lastname}`
        }))
      })
  },
  methods: {
    save() {
      if(this.$refs.form.validate()) {
        if (!this.editedItem.groceries.length) {
          this.validate = false;
          return
        }

        this.$emit('save', this.editedItem)

      }
    }
  },
  computed: {
    restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    }
  },
  watch: {
    item() {
      this.editedItem = this.item
    }
  }
}

</script>

<style>
.a4-page {
  width: 210mm;
  /*height: 297mm;*/
  margin: 16px auto;
  padding: 16mm;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #ddd;
}
</style>
