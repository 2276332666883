<template>
  <v-container>
    <v-card>
      <v-tabs
        v-model="tab"
        dark
        background-color="primary"
      >
        <v-tabs-slider color="black"></v-tabs-slider>

        <v-tab
          v-for="item in items"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <!-- 'Trebovanje' -->
          <requirement v-if="tab === 0"></requirement>
        </v-tab-item>
        <v-tab-item>
          <!-- 'Stanje' -->
          <inventory v-if="tab === 1" @changeTab="changeTab"></inventory>
        </v-tab-item>
        <v-tab-item>
          <!-- 'Naruceno'  -->
          <purchase v-if="tab === 2"></purchase>
        </v-tab-item>
        <v-tab-item>
          <!-- 'Izdavanje' -->
          <invoice v-if="tab === 3"></invoice>
        </v-tab-item>
        <v-tab-item>
          <!-- 'Transakcije' -->
          <transaction v-if="tab === 4" :selected-grocery="selectedGrocery"></transaction>
        </v-tab-item>
        <v-tab-item>
          <!-- 'Magacini' -->
          <warehouse v-if="tab === 5"></warehouse>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>

import warehouse from "../components/warehouse/warehouse";
import inventory from "../components/warehouse/inventory";
import invoice from "../components/warehouse/invoice";
import transaction from "../components/warehouse/transaction";
import requirement from "../components/warehouse/requirement";
import purchase from "../components/warehouse/purchase";

export default {
  components: {warehouse, inventory, invoice, transaction, requirement, purchase},
  data () {
    return {
      tab: 0,
      items: [
        'Trebovanje',
        'Stanje',
        'Naruceno',
        'Izdavanje',
        'Transakcije',
        'Magacini'
      ],
      selectedGrocery: null
    }
  },
  methods: {
    changeTab(item) {
      this.tab = item.tab
      this.selectedGrocery = item.grocery
    }
  }
}
</script>
