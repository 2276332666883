<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title>
        Import .CSV
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters>
              <input type="file" id="input" @change="excel"/>
              <v-spacer></v-spacer>
              <v-btn outlined color="primary" small @click="importUsers">
                <v-icon>mdi-upload</v-icon>
                Import
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    id
                  </th>
                  <template v-for="item in Object.keys(schema)">
                    <th class="text-left">
                      {{item}}
                    </th>
                  </template>
                  <th class="text-left">Status</th>
                  <th class="text-left">
                    Progress
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(item, i) in items"
                  :key="i"
                >
                  <td>{{ i + 1 }}</td>
                  <td>{{ item.name || '-' }}</td>
                  <td>{{ item.lastname || '-' }}</td>
                  <td>{{ item.password || '-' }}</td>
                  <td>{{ item.email || '-' }}</td>
                  <td v-html="item.info"></td>
<!--                  <td>{{ item.state }}</td>-->
                  <td>
                    <span :class="progressClass(item.statusImport)">{{ item.statusImport }}</span>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import readXlsxFile from 'read-excel-file'
import axios from  '../plugins/axios'
import Papa from 'papaparse'

export default {
  props: {
    dialog: Boolean,
    default: false
  },
  data() {
    return {
      items: [],
      errors: [],
      schemaNew: [
        'name', 'lastname', 'password', 'email'
      ],
      schema: {
        'First Name': {
          prop: 'name',
          type: String
        },
        'Last Name': {
          prop: 'lastname',
          type: String
        },
        'Password': {
          prop: 'password',
          type: String
        },
        'Email': {
          prop: 'email',
          type: String
        }
      }
    }
  },
  methods: {
    progressClass(status) {

      switch(status) {
        case 'ready': return 'info--text'
        break
        case 'not ready': return 'warning--text'
        break
        case 'success': return 'success--text'
        break
        case 'failed': return 'error--text'
        break
        case 'warning': return 'warning--text'
        break
      }

    },
    async importUsers() {
      const chunkSize = 10;

      for (let i = 0; i < this.items.length; i += chunkSize) {
        let items = this.items.slice(i, i + chunkSize)
        items = items.filter(i => i.state)

        //await new Promise(resolve => setTimeout(resolve, 1000));

        await axios.post('/import/users/chunk', {
          users: items,
          company: this.$route.params.id
        }).then(res => {

          res.data.data.forEach(data => {
            let find = items.filter(item => item.email === data.email)
            if(find) {
              find.forEach(i => i.statusImport = data.state)
            }
          })

        })

      }

    },
    excel(e) {
      if(e.target.files[0] && !e.target.files[0].name.includes('.csv')) return;
      let arr = []
      Papa.parse(e.target.files[0], {
        complete(results) {
          results.data.forEach((item, index) => {
            if(index > 0) {

              let arr2 = {};
              if(!item[0] || !item[1] || !item[2]) {
                arr2.state = false
                arr2.statusImport = 'not ready'
                arr2.info = '<span class="error--text">Failed</span>'
              }
              else {
                arr2.state = true
                arr2.statusImport = 'ready',
                arr2.info = '<span class="success--text">OK</span>'
              }

              arr.push({
                name: item[0],
                lastname: item[1],
                email: item[2],
                ...arr2
              })
            }
          });
        }
      });

      this.items = arr
    },
    state(item) {
      if(item && item.column) {
        return item.column + ' <span class="error--text">' + item.reason.replaceAll('_', ' ') + '</span>'
      }
      else {
        return '<span class="success--text">OK</span>'
      }
    }
  },
  filters: {
    reason(item) {
      return item.replaceAll('_', ' ')
    }
  }
}

</script>
