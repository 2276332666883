<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="title">
        <v-row>
        <v-col :cols="row === 'company' ? 4 : 8">
        FCM Notifikacije <span>({{ restaurant ? restaurant.name : 'Restoran nije selektovan' }})</span>
        </v-col>
        <v-col cols="6" v-if="row === 'company'">
          <v-row>
            <v-col cols="3">
              <v-checkbox
                  @click="selectAllFirmUsers()"
                  v-model="firm_users"
                  :label="translate('Сви корисници')"
                  hide-details>
              </v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    style="max-width: 290px"
                    :label="translate('Нису наручили на време')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn style="border-radius: 8px" text color="primary" @click="menu = false">
                  {{ $t("close") }}
                </v-btn>
                <v-btn style="border-radius: 8px" text color="primary" @click="$refs.menu.save(date);loadOrdersTime()">
                  {{ $t("confirm") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
            </v-col>
            <v-col cols="4">
             <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="date1"
                transition="scale-transition"
                offset-y
                max-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date1"
                    style="max-width: 290px"
                    :label="translate('Наручили ниси преузели')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="date1" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn style="border-radius: 8px" text color="primary" @click="menu1 = false">
                  {{ $t("close") }}
                </v-btn>
                <v-btn style="border-radius: 8px" text color="primary" @click="$refs.menu1.save(date1);loadNotTakenOrders()">
                  {{ $t("confirm") }}
                </v-btn>
              </v-date-picker>
            </v-menu>
            </v-col>
          </v-row>
        </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="projects"
                  v-model="notification.project"
                  label="Projekat"
                  filled
                  hide-details
                  dense
                  item-text="name"
                  item-id="code"
                  return-object
                >
                </v-select>
                <br>
                <v-radio-group
                  v-model="row"
                  row
                >
                  <v-radio
                    label="Restoran"
                    value="restaurant"
                  ></v-radio>
                  <v-radio
                    label="Kompanije"
                    value="company"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field solo dense label="Title" hide-details v-model="title"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea solo dense label="Message" hide-details v-model="message"></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8">
            <v-row>
              <v-col cols="12" v-if="row === 'company'">
                <v-row>
                  <v-col>
                    <v-select
                      :items="companies"
                      v-model="selectedCompanies"
                      label="Company"
                      filled
                      hide-details
                      dense
                      item-text="name"
                      item-id="id"
                      return-object
                      chips
                      multiple
                      :append-icon="selectedCompanies.length ? 'mdi-minus-box-outline' : 'mdi-plus-box-outline'"
                      @click:append="selectedCompanies.length ? selectedCompanies = [] : selectedCompanies = companies"
                    >
                      <v-btn color="primary" slot="prepend-item" @click="toogleAllCompanies()" small class="mx-4">
                        {{ $t("display_all") }}
                      </v-btn>
                    </v-select>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn @click="getUsers" color="primary" height="52px" class="mx-2">Trazi</v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <p v-if="count_orders" style="display: flex; justify-content: space-between;">
                  <span class="font-weight-bold">{{ ($t('ordered_qty')).toUpperCase() + ': ' +  count_orders }}</span>
                  <span class="font-weight-bold">{{ (translate('Не') + ' ' + $t('ordered_qty')).toUpperCase() + ': ' +  users.length }}</span>
                </p>
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="users"
                  :items-per-page="5"
                  class="elevation-1"
                  item-key="id"
                  show-select
                >
                  <template v-slot:item.user.messageStatus="{ item }">
                    {{ item.messageStatus || 'pending' }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-btn @click="send" color="primary" :loading="loading" :disable="loading">Send</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

  import axios from '../plugins/axios'
  import moment from "moment/moment";

  export default {
    data() {
      return {
        selected: [],
        date: '',
        date1: '',
        menu: false,
        menu1: false,
        notification: {
          project: {code: 'ordera-e6eb8', name: 'Ordera'}
        },
        projects: [
          {code: 'ordera-e6eb8', name: 'Ordera'},
          {code: 'order-notification-8f5f2', name: 'Restorani'}
        ],
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Lastname', value: 'lastname' },
          { text: 'Email', value: 'email' },
          { text: 'Tip', value: 'type' },
          { text: 'State', value: 'messageStatus' }
        ],
        users: [],
        originalUsers: [],
        loading: false,
        info: {
          state: false,
          list: []
        },
        title: '',
        message: '',
        companies: [],
        selectedCompanies: [],
        row: 'restaurant',
        firm_users: false,
        no_order_on_time_users: false,
        orders_not_taken: false,
        count_orders: null
      }
    },
    created() {
      this.getUsers()
      this.loadCompanies()
    },
    methods: {
      toogleAllCompanies() {
        if (this.selectedCompanies.length > 0) {
          this.selectedCompanies = []
          this.getUsers()
        } else if (this.selectedCompanies.length == 0) {
          this.selectedCompanies = []
          // for(let company in this.report_food_original) {
          //   this.searchFoodCompany.push(company)
          // }
          this.companies.filter(com => {
            this.selectedCompanies.push(com)
          })
          this.getUsers()
        }
        this.$forceUpdate()
      },
      selectAllFirmUsers() {
        if (this.firm_users) {
          this.users = this.originalUsers
          this.date = ''
          this.date1 = ''
          this.count_orders = null
        }
      },
      loadNotTakenOrders() {
        if (this.selectedCompanies.length) {
          let firme = []
          let korisnikId = []
          this.selectedCompanies.filter(item => {
            firme.push(item.id)
          })
          this.users.filter(user => {
            korisnikId.push(user.id)
          })
          axios.post('/orders/filter-not-taken-orders', { companies: firme, date: this.date1, users: korisnikId }).then(({data}) => {
            this.users = data.no_order
            this.firm_users = false
          })
        }
      },
      loadOrdersTime() {
        if (this.selectedCompanies.length) {
          let firme = []
          let korisnikId = []
          this.selectedCompanies.filter(item => {
            firme.push(item.id)
          })
          this.users.filter(user => {
            korisnikId.push(user.id)
          })
          axios.post('/orders/filter-not-on-time-orders', { companies: firme, date: this.date, users: korisnikId }).then(({data}) => {
            this.users = data.no_order
            this.count_orders = data.order
            this.firm_users = false
          })
        }
      },
      loadCompanies() {
        this.companies = []
        axios.get('/companies?restaurants').then(res => {
          let companies = res.data
          companies.forEach(item => {
            if(item.children && item.children.length) {
              this.companies = this.companies.concat(item.children)
            }
            else {
              this.companies.push(item)
            }
          })
        })
        this.$forceUpdate()
      },
      getUsers() {
        let data = ''
        if(this.row === 'restaurant') {
          data = 'restaurant_ids=' + this.restaurant.id
        }
        else {
          data = 'company_ids=' + this.selectedCompanies.map(i => i.id)
        }
        axios.get('https://app.ordera.app/api/devices/get-users?project=' + this.notification.project.code + '&row=' + this.row + '&' + data).then(res => {
          this.users = res.data
          this.originalUsers = res.data
        })
      },
      send() {
        this.loading = true
        axios.post('https://app.ordera.app/api/devices/send-fcm', {
          'project': this.notification.project.code,
          'user_ids': this.selected.map(user => user.id),
          'title': this.title,
          'body': this.message
        }).then(res => {
          this.info.list = res.data
          this.users = this.users.map(user => {
            let newUserSuccess = this.info.list.find(i => i.user_id === user.id && i.response.name)
            let newUserFailed = this.info.list.find(i => i.user_id === user.id && i.response.error)

            if(!user.messageStatus) {
              if (newUserSuccess) {
                user.messageStatus = 'ok'
              } else {
                if (newUserFailed) {
                  user.messageStatus = 'failed'
                }
              }

              if (!newUserSuccess && !newUserFailed) {
                user.messageStatus = 'not found token'
              }
            }


            return user;
          })
        }).finally(() => {
          this.loading = false
          this.info.state = true
        })
      }
    },
    computed: {
      dateRangeText() {
        if (this.date.length == 1 || this.date[0] == this.date[1]) {
          return moment(this.date[0]).format("DD.MM.YYYY");
        }
        return (
            moment(this.date[0]).format("DD.MM.YYYY") +
            " - " +
            moment(this.date[1]).format("DD.MM.YYYY")
        );
      },
      restaurant() {
        return this.$store.state.restaurant
      }
    },
    watch: {
      restaurant() {
        this.getUsers()
        this.loadCompanies()
      },
      'notification.project'() {
        this.getUsers()
      },
      row() {
        this.getUsers()
      }
    }
  }

</script>
