<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    class="elevation-1"
    dense
    :expanded.sync="expanded"
    item-key="name"
    :show-expand="false"
    single-expand
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Stanje u Magainu</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-autocomplete label="Magacin" v-model="selectedWarehouse" :items="warehouse" item-text="name" hide-details dense clearable></v-autocomplete>
        <v-text-field label="Pretraga" hide-details dense v-model="search" clearable class="ml-2"></v-text-field>
        <v-dialog
          v-model="dialog"
          max-width="600px"
          scrollable
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Dodaj
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>


            <v-card-text style="height: 600px;">

                <v-sheet color="#fffded" class="a5-page">
                    <v-row>
                      <v-col cols="12" class="text-h5 font-weight-bold">
                        {{restaurant.name}}
                      </v-col>
                      <v-col cols="12" class="text-h6 font-weight-bold">
                        <v-autocomplete label="Magacin" v-model="editedItem.warehouse" :items="warehouse" item-text="name" item-value="id" return-object hide-details filled></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="mt-5" no-gutters>
                          <v-col cols="5" align="start">
                            <v-text-field label="Robu izdao" hide-details filled class="mb-6"></v-text-field>
                            <v-text-field label="Robu preuzeo" hide-details filled></v-text-field>
                          </v-col>
                          <v-col cols="1"></v-col>
                          <v-col cols="6" align="end">
                            <v-textarea label="Detalji" hide-details filled rows="4"></v-textarea>
                          </v-col>
                          <v-col cols="12" class="mt-6">
                            <v-row>
                              <v-col cols="6">
                                <v-autocomplete
                                  v-model="editedItem.grocery"
                                  :items="groceries"
                                  label="Proizvod"
                                  item-text="name"
                                  return-object
                                  hide-details filled
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="editedItem.code"
                                  label="Sifra"
                                  hide-details filled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="editedItem.quantity"
                                  label="Kolicina"
                                  hide-details filled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <v-select
                                  v-model="editedItem.measure"
                                  label="Merna Jedinica"
                                  :items="['kg', 'l', 'kom']"
                                  hide-details filled
                                ></v-select>
                              </v-col>

                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-sheet>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        More info about {{ item.name }}
      </td>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="$emit('changeTab', {tab: 3, grocery: item})"
      >
        mdi-swap-horizontal-bold
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        disabled
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>


<script>
import axios from "../../plugins/axios";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Magacin', value: 'warehouse.name' },
      { text: 'Sifra', value: 'code' },
      {
        text: 'Proizvod',
        align: 'start',
        value: 'grocery.name',
      },
      { text: 'Kolicina', value: 'quantity' },
      { text: 'Merna Jedinica', value: 'measure' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    _desserts: [],
    editedIndex: -1,
    editedItem: {
      grocery: null,
      warehouse: null,
      quantity: 0,
      measure: '',
      code: ''
    },
    defaultItem: {
      grocery: null,
      warehouse: null,
      quantity: 0,
      measure: '',
      code: ''
    },
    expanded: [],
    groceries: [],
    warehouse: [],
    search: '',
    selectedWarehouse: null
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    }
  },

  watch: {
    selectedWarehouse() {
      if(this.selectedWarehouse) {
        this.desserts = this._desserts.filter(i => i.warehouse.name === this.selectedWarehouse)
      }
      else {
        this.desserts = this._desserts
      }
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      axios.get("/groceries?warehouse").then((res) => {
        this.groceries = res.data;
      });
      axios.get("/warehouse-new").then((res) => {
        this.warehouse = res.data;
      });
      axios.get("/inventory").then((res) => {
        this.desserts = res.data;
        this._desserts = res.data;
      });
    },

    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        let _editedIndex = this.editedItem
        let _editedItem = this._editedItem
        axios.put('/inventory/' + this.editedItem.id, this.editedItem).then(res => {
          Object.assign(this.desserts[_editedIndex], _editedItem);
        })
      } else {
        let _this = this
        let data = {
          'grocery_id': this.editedItem.grocery.id,
          'warehouse_id': this.editedItem.warehouse.id,
          'quantity': this.editedItem.quantity,
          'measure': this.editedItem.measure,
          'code': this.editedItem.code
        }
        axios.post('/inventory', data).then(res => {
          let editedIndex = _this.desserts.findIndex(i => i.grocery_id == data.grocery_id)
          if(editedIndex > -1) {
            _this.desserts[editedIndex].quantity += this.editedItem.quantity;
          }
          else {
            _this.desserts.push(res.data)
          }
        })
      }
      this.close()
    },
  }
}
</script>

<style>
.a5-page {
  width: 148mm;
  height: 210mm;
  margin: 16px auto;
  padding: 16mm;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #ddd;
}
</style>
