<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="documents"
      class="elevation-1"
      dense
      show-expand
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Naruceno</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="850"
            scrollable
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nova Narudzba
              </v-btn>
            </template>
            <invoice-template @close="dialog = false" :item="editedItem" @save="saveInvoice" transaction-type="order" :disabled="true"></invoice-template>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              @click="dialog2 = true; items = item.groceries; editedItem = item"
              v-bind="attrs"
              v-on="on"
            >
              mdi-file-document-arrow-right-outline
            </v-icon>
          </template>
          <span>Dobavljac Isporucio</span>
        </v-tooltip>


        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          disabled
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          disabled
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.status="{ item }">
        <v-autocomplete hide-details solo v-model="item.status" dense :items="status" @change="updateStatus(item)"></v-autocomplete>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format('DD-MM-YYYY HH:mm') }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-4">
          <div class="">Ulaz</div>
          <v-data-table
            :headers="headers"
            :items="item.children"
            class="elevation-1"
            dense
            hide-default-footer
          >
            <template v-slot:item.actions="{hearder, item}">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog2" fullscreen>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog2 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Ulaz</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog2 = false"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pa-5">
          <v-row v-if="editedItem" class="px-4 pb-4 text-h6" no-gutters>
            <div class="pr-4">R.b: {{editedItem.id}}</div>
            <div class="pr-4">Datum: {{editedItem.document_date}}</div>
            <div class="pr-4">Narucilac: {{editedItem?.customer?.name}} {{editedItem?.customer?.lastname}}</div>
          </v-row>
          <v-data-table
            v-model="editedItem.groceries"
            :headers="headers2"
            :items="items"
            item-key="name"
            show-select
            :loading="loading"
            :items-per-page="-1"
            hide-default-footer
          >
            <template v-slot:item.stock="{item}">
              <td :style="{backgroundColor: item.stock < item.quantity ? 'yellow' : '#86df86'}">{{item.stock}}</td>
            </template>
            <template v-slot:item.order="{item}">
              <td>
                <v-text-field hide-details solo v-model="item.order" dense type="number"></v-text-field>
              </td>
            </template>
            <template v-slot:item.created_at="{item}">
              {{ moment(item.created_at).format('DD-MM-YYYY HH:mm') }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog3 = true" color="#fb6d3a" block dark>Napravi Ulaz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog3" scrollable max-width="850">
      <invoice-template @close="dialog3 = false" :item="editedItem" @save="saveInvoice" transaction-type="entry"></invoice-template>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.state"
      :timeout="4000"
      :color="snackbar.color"
      height="80"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>


<script>
import axios from "../../plugins/axios";
import moment from "moment";
import InvoiceTemplate from "./invoice-template";

export default {
  components: {InvoiceTemplate},
  data() {
    return {
      moment: moment,
      snackbar: {
        state: false,
        color: 'success',
        text: ''
      },
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'R.b',
          align: 'start',
          value: 'id',
        },
        {text: 'Opis', value: 'notes'},
        {text: 'Datum Otpremnice', value: 'document_date'},
        {text: 'Datum', value: 'created_at'},
        {text: 'Status', value: 'status'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      documents: [],
      editedIndex: -1,
      editedItem: {
        dispatched_by: null,
        received_by: null,
        ordered_by: null,
        notes: null,
        warehouse: null,
        document_type: 'order',
        document_date: moment().format('YYYY-MM-DD'),
        groceries: []
      },
      defaultItem: {
        dispatched_by: null,
        received_by: null,
        ordered_by: null,
        notes: null,
        warehouse: null,
        document_type: 'order',
        document_date: null,
        groceries: []
      },

      headers2: [
        {
          text: 'Naziv',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Sifra',
          align: 'start',
          value: 'code',
        },
        {
          text: 'Naruceno',
          align: 'start',
          value: 'quantity',
        },
        {
          text: 'Isporuceno',
          align: 'start',
          value: 'order',
          sortable: false
        },
        {text: 'Jedinica Mere', value: 'measure'}
      ],

      dialog2: false,
      dialog3: false,


      people: {
        from: '',
        to: ''
      },
      quantity: 0,
      items: [],

      loading: false,

      status: [
        'created',
        'sent',
        'confirm',
        'confirm-half',
        'reject-supplier',
        'changed-in-progress',
        'waiting-to-other-supplier',
        'reject',
        'delivered',
        'delivered-half',
        'on-checking',
        'reject-on-arrived',
        'accept',
        'closed',
        'undefined'
      ]
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()

    this.load()
  },

  methods: {
    updateStatus(item) {
      axios.patch('/warehouse-new/' + item.id, {data: {status: item.status}})
        .then(res => {
          this.snackbar = {
              state: true,
              color: 'success',
              text: 'Uspesno promenjen status'
          }
        })
        .catch(() => {
          this.snackbar = {
            state: true,
            color: 'success',
            text: 'Greska promene statusa'
          }
        })
    },

    initialize () {

    },

    editItem (item) {
      this.editedIndex = this.documents.indexOf(item)
      this.editedItem = Object.assign({}, item)
      console.log('this.editedItem', this.editedItem)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.documents.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.documents.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    saveInvoice() {
      this.loading = true

      this.editedItem.document_type = 'entry'
      this.editedItem.parent_id = this.editedItem.id
      this.editedItem.warehouse_id = typeof this.editedItem.warehouse === 'object' ? this.editedItem.warehouse.id : this.editedItem.warehouse
      this.editedItem.groceries = this.editedItem.groceries.map(grocery => {
        grocery.warehouse_id = typeof this.editedItem.warehouse === 'object' ? this.editedItem.warehouse.id : this.editedItem.warehouse
        grocery.grocery_id = grocery.id
        grocery.transaction_type = 'entry'
        grocery.quantity = grocery.order
        return grocery
      })
      axios.post('/warehouse-transaction/store-more', this.editedItem)
        .finally(() => {
          this.loading = false
          this.dialog3 = false
        })
    },

    save () {
      if (this.editedIndex > -1) {
        let _editedIndex = this.editedItem
        let _editedItem = this._editedItem
        axios.put('/warehouse-new/' + this.editedItem.id, this.editedItem).then(res => {
          Object.assign(this.documents[_editedIndex], _editedItem);
        })
      } else {
        let _this = this
        axios.post('/warehouse-new', this.editedItem).then(res => {
          _this.documents.push(res.data)
        })
      }
      this.close()
    },

    load() {
      axios.get('/warehouse-new/documents?document_type=order').then(res => {
        this.documents = res.data.map(item => {
          item.groceries = item.groceries.map(grocery => {
            grocery.order = grocery.quantity
            return grocery
          })
          return item
        })
      })
    }
  },
}
</script>
