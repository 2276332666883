import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home'
import HomeGuest from '../pages/HomeGuest'
import GuestHome from '../pages/GuestHome'
import Lokacija from '../pages/Lokacija'
import Poruke from '../pages/Poruke'
import Posts from '../pages/Posts'
import Jela from '../pages/Jela'
import Jelovnik from '../pages/Jelovnik'
import Firme from '../pages/Firme'
import FirmeDostava from '../pages/FirmeDostava'
import PorudzbeDostava from '../pages/PorudzbeDostava'
import Profil from '../pages/Profil'
import EditProfile from '../pages/edit-profile'
import Korisnici from '../pages/Korisnici'
import Logovanje from '../pages/Logovanje'
import Naplata from '../pages/Naplata'
import Izvestaj from '../pages/Izvestaj'
import Warehouse from '../pages/Warehouse'
// import IzvestajB2B from '../pages/IzvestajB2B'
import IzvestajNaplata from '../pages/IzvestajNaplata'
import IzvestajGost from '../pages/IzvestajGost'
import izvestajFirma from '../pages/firma-izvestaj'
import Restorani from '../pages/Restorani'
import RestoraniGuest from '../pages/RestoraniGuest'
import Namirnice from '../pages/Namirnice'
import Magacin from '../pages/Magacin'
import Poslovnice from '../pages/Poslovnice'
import Podesavanja from '../pages/Podesavanja'
import Golerija from '../pages/Galerija'
import Kategorije from '../pages/Kategorije'
import Menu from '../pages/Menu'
import QRlist from '../pages/QRlist'
import QRkod from '../pages/QRkod'
import ForgotPassword from '../pages/forgot-password'
import ResetPassword from '../pages/reset-password'
import ChangePassword from '../pages/change-password'
import Info from '../pages/Info'
import Istorijat from '../pages/Istorijat'
import store from '../store/index'
import axios from '../plugins/axios'
import Dostava from '../pages/Dostava'
import RestoranDostava from '../pages/restoran-dostava'
import Notification from '../pages/Notification'
import Report from '../pages/Report'
import CategoryMenu from '../pages/category-menu'
import CategoryMenuGroup from '../pages/category-menu-group'
import CategoryMenuAdd from '../pages/category-menu-add'
import IzvestajPosta from '../pages/PostReport'
import PostReport from "@/pages/PostReport.vue";
import FcmNotification from "../pages/FcmNotification";
import Questionnaire from "../pages/Questionnaire";
import UserGuestList from "../pages/UserGuestList";

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/izvestaj-b2b',
    //     name: 'izvestajB2b',
    //     component: IzvestajB2B,
    //     permission: ['admin', 'restaurant', 'report']
    // },
    {
        path: '/izvestaj',
        name: 'izvestaj',
        component: Izvestaj,
        permission: ['admin', 'restaurant', 'report']
    },
    {
        path: '/izvestaj-gost',
        name: 'izvestaj-gost',
        component: IzvestajGost,
        permission: ['admin', 'restaurant', 'report', 'report_guest', 'recipient', 'shop']
    },
    {
        path: '/izvestaj-posta',
        name: 'izvestaj-posta',
        component: PostReport,
        permission: ['admin', 'admin_post', ]
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        permission: ['customer', 'company', 'worker_more_restaurants']
    },
    {
        path: '/guest',
        name: 'guest',
        component: HomeGuest,
        permission: ['guest', 'company', 'customer']
    },
    {
        path: '/jela',
        name: 'jela',
        component: Jela,
        permission: ['admin', 'restaurant', 'shop']
    },
    {
        path: '/jelovnik',
        name: 'jelovnik',
        component: Jelovnik,
        permission: ['admin', 'restaurant']
    },
    {
        path: '/firme',
        name: 'firme',
        component: Firme,
        permission: ['admin','restaurant']
    },
    {
        path: '/firmeDostava',
        name: 'firme-dostava',
        component: FirmeDostava,
        permission: ['admin','restaurant', 'company','dostava', 'admin_delivery']
        // 'company',
    },
    {
        path: '/delivery-user-orders',
        name: 'delivery-user-orders',
        component: PorudzbeDostava,
        permission: ['admin','restaurant', 'company', 'dostava', 'admin_delivery']
    },
    {
        path: '/poslovnice',
        name: 'poslovnice',
        component: Poslovnice,
        permission: ['admin','company']
    },
    {
        path: '/profil',
        name: 'profil',
        component: Profil,
        permission: ['restaurant','admin','customer','company', 'guest']
    },
    {
        path: '/promjena-profila',
        name: 'edit-profile',
        component: EditProfile,
        permission: ['restaurant','admin','customer','company', 'guest']
    },
    {
        path: '/restorani',
        name: 'restorani',
        component: Restorani,
        permission: ['admin', 'restaurant', 'dostava', 'admin_delivery']
    },
    {
        path: '/restaurants',
        // path: '/:lang/restorani',
        name: 'restorani-guest',
        component: RestoraniGuest,
        permission: ['admin', 'restaurant', 'guest', 'dostava']
    },
    {
        path: '/firma/:id/korisnici',
        name: 'firma-id-korisnici',
        component: Korisnici,
        permission: ['admin', 'restaurant','company', 'guest', 'admin_delivery']
    },
    {
        path: '/restoran/:id/korisnici',
        name: 'restoran-id-korisnici',
        component: Korisnici,
        permission: ['admin', 'restaurant']
    },
    {
        path: '/restoran/:id/dostava',
        name: 'restoran-dostava',
        component: RestoranDostava,
        permission: ['restaurant','admin','customer','company', 'guest']
    },
    {
        path: '/korisnici',
        name: 'korisnici',
        component: Korisnici,
        permission: ['company']
    },
    {
        path: '/logovanje',
        name: 'logovanje',
        component: Logovanje,
        permission: ['admin', 'restaurant', 'customer']
    },
    {
        path: '/naplata',
        name: 'naplata',
        component: Naplata,
        permission: ['restaurant', 'recipient', 'payment']
    },
    {
        path: '/naplata/izvestaj-naplata',
        name: 'izvestaj-naplata',
        component: IzvestajNaplata,
        permission: ['admin', 'restaurant', 'report', 'recipient']
    },
    {
        path: '/guest-home',
        name: 'guest-home',
        component: GuestHome,
        permission: ['restaurant', 'recipient']
    },
    {
        path: '/firma/izvestaj',
        name: 'izvestajFirma',
        component: izvestajFirma,
        permission: ['company']
    },
    {
        path: '/namirnice',
        name: 'namirnice',
        component: Namirnice,
        permission: ['admin', 'restaurant']
    },
    {
        path: '/delivery',
        name: 'delivery',
        component: Dostava,
        permission: ['admin', 'restaurant', 'customer', 'company']
    },
    {
        path: '/magacin',
        name: 'magacin',
        component: Magacin,
        permission: ['admin', 'restaurant', 'warehouse']
    },
    {
        path: '/podesavanja',
        name: 'podesavanja',
        component: Podesavanja,
        permission: ['admin', 'restaurant']
    },
    {
        path: '/galerija',
        name: 'galerija',
        component: Golerija,
        permission: ['admin', 'restaurant']
    },
    {
        path: '/kategorije',
        name: 'kategorije',
        component: Kategorije,
        permission: ['admin', 'restaurant', 'shop']
    },
    {
        path: '/menu',
        name: 'menu',
        component: Menu,
        permission: ['admin', 'restaurant']
    },
    {
        path: '/zaboravljena-lozinka',
        name: 'zaboravljena-lozinka',
        component: ForgotPassword,
        permission: ['admin', 'restaurant', 'customer', 'company']
    },
    {
        path: '/resetovanje-lozinke',
        name: 'resetovanje-lozinke',
        component: ResetPassword,
        permission: ['admin', 'restaurant', 'customer', 'company']
    },
    {
        path: '/promjena-lozinke',
        name: 'promjena-lozinke',
        component: ChangePassword,
        permission: ['admin', 'restaurant', 'customer', 'company', 'guest']
    },
    {
        path: '/istorijat',
        name: 'istorijat',
        component: Istorijat,
        permission: ['customer']
    },
    {
        path: '/lokacija',
        name: 'lokacija',
        component: Lokacija,
        permission: ['customer', 'company']
    },
    {
        path: '/info',
        name: 'info',
        component: Info,
        permission: ['admin', 'restaurant', 'customer', 'company', 'guest', 'recipient', 'report_guest']
    },
    {
        path: '/qrlist',
        name: 'QRlist',
        component: QRlist,
        props:true,
        permission: ['admin', 'restaurant','company', 'report_guest']
    },
    {
        path: '/qrkod',
        name: 'qrShow',
        component: QRkod,
        permission: ['admin', 'restaurant','company','customer', 'guest', 'payment']
    },
    {
        path: '/notification',
        name: 'notification',
        component: Notification,
        props:true,
        permission: ['admin', 'restaurant','company']
    },
    {
      path: '/report',
      name: 'report',
      component: Report,
      props:true,
      permission: ['admin', 'restaurant','company','customer', 'guest']
    },
    {
      path: '/category-menu',
      name: 'category-menu',
      component: CategoryMenu,
      permission: ['admin', 'restaurant', 'guest', 'company', 'customer']
    },
    {
        path: '/poruke',
        name: 'poruke',
        component: Poruke,
        permission: ['admin']
    },
    {
        path: '/posts',
        name: 'posts',
        component: Posts,
        permission: ['admin']
    },
    {
      path: '/fcm-notification',
      name: 'fcm-notification',
      component: FcmNotification,
      permission: ['admin', 'restaurant', 'company', 'customer']
    },
  {
    path: '/category-menu-group',
    name: 'category-menu-group',
    component: CategoryMenuGroup,
    permission: ['admin', 'restaurant', 'guest', 'company', 'customer']
  },
  {
    path: '/category-menu-add',
    name: 'category-menu-add',
    component: CategoryMenuAdd,
    permission: ['admin', 'restaurant', 'guest', 'company', 'customer']
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: Questionnaire,
    permission: ['admin', 'restaurant', 'guest', 'company', 'customer']
  },
  {
    path: '/users-guest-list',
    name: 'users-guest-list',
    component: UserGuestList,
    permission: ['admin']
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: Warehouse,
    permission: ['admin', 'restaurant', 'warehouse']
  },
  /*{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
  }*/
   // admin_delivery
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
    if(store.state.user) {
        let route = routes.find(i => i.name === to.name)
        if(route && route.permission.includes(store.state.user.type)) next()
    }
    else if(!store.state.user && localStorage.getItem('restoran-token') && to.name !== 'logovanje'){
        axios.get('/user/me').then(res => {
            store.commit('SAVE_USER', res.data)
            if(res.data.restaurants && res.data.restaurants.length) {
                store.commit('SAVE_RESTAURANT', res.data.restaurants[0])
                store.commit('SAVE_COMPANY', null)
                store.commit('SAVE_COMPANIES', null)
            }
            if(res.data.companies && res.data.companies.length) {
                store.commit('SAVE_COMPANY', res.data.companies[0])
                store.commit('SAVE_COMPANIES', res.data.companies)
                store.commit('SAVE_RESTAURANT', null)

            }
        }).then(() => {
            let route = routes.find(i => i.name === to.name)
            if(!route) {
                let routeName = routes.find(i => i.permission.includes(store.state.user.type))
                next({name: routeName.name})
                //next({name:'jela'})
            }
            if(route && route.permission.includes(store.state.user.type)) {
                next()
            }
        })
    }
    else if(!store.state.user && !localStorage.getItem('restoran-token') && (to.name === 'zaboravljena-lozinka' || to.name === 'resetovanje-lozinke')){
        next()
    } else
    if(!store.state.user && to.name === 'restorani-guest' && to.name !== 'logovanje') {
        next()
    } else if(!store.state.user && to.name === 'guest' && to.name !== 'logovanje') {
        next()
    }
    else if(to.name !== 'logovanje'){
        next({name:'logovanje'})
    }
    else{
        next()
    }
})

export default router
