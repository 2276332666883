<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="documents"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Otpremnice</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="850"
            scrollable
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Novo Otpremnica
              </v-btn>
            </template>
            <invoice-template @close="dialog = false" :item="editedItem" @save="saveInvoice"></invoice-template>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          disabled
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format('DD-MM-YYYY HH:mm') }}
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
          disabled
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>


<script>
import axios from "../../plugins/axios";
import moment from "moment";
import InvoiceTemplate from "./invoice-template";

export default {
  components: {InvoiceTemplate},
  data: () => ({
    moment: moment,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'R.b',
        align: 'start',
        value: 'id',
      },
      { text: 'Opis', value: 'notes' },
      { text: 'Datum Otpremnice', value: 'document_date' },
      { text: 'Datum', value: 'created_at' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    documents: [],
    editedIndex: -1,
    editedItem: {
      dispatched_by: null,
      received_by: null,
      ordered_by: null,
      notes: null,
      warehouse: null,
      document_type: 'exit',
      document_date: moment().format('YYYY-MM-DD'),
      groceries: []
    },
    defaultItem: {
      dispatched_by: null,
      received_by: null,
      ordered_by: null,
      notes: null,
      warehouse: null,
      document_type: 'exit',
      document_date: null,
      groceries: []
    },


    dialog2: false,


    people: {
      from: '',
      to: ''
    },
    quantity: 0,
    items: [],

    loading: false,

  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    }
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()

    this.load()
  },

  methods: {
    initialize () {

    },

    editItem (item) {
      this.editedIndex = this.documents.indexOf(item)
      this.editedItem = Object.assign({}, item)
      console.log('this.editedItem', this.editedItem)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.documents.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.documents.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    saveInvoice(e) {
      this.editedItem = e

      this.loading = true
      let groceries = [];

      this.items.forEach(item => {
        groceries.push({
          'grocery_id': item.id,
          'warehouse_id': this.warehouse.id,
          'quantity': item.quantity,
          'measure': item.measure,
          'transaction_type': 'exit'
        })
      })

      this.editedItem.warehouse_id = this.editedItem.warehouse.id
      axios.post('/warehouse-transaction/store-more', this.editedItem)
        .finally(() => {
          this.dialog = false
          this.loading = false
        })
    },

    save () {
      if (this.editedIndex > -1) {
        let _editedIndex = this.editedItem
        let _editedItem = this._editedItem
        axios.put('/warehouse-new/' + this.editedItem.id, this.editedItem).then(res => {
          Object.assign(this.documents[_editedIndex], _editedItem);
        })
      } else {
        let _this = this
        axios.post('/warehouse-new', this.editedItem).then(res => {
          _this.documents.push(res.data)
        })
      }
      this.close()
    },

    load() {
      axios.get('/warehouse-new/documents?document_type=exit').then(res => {
        this.documents = res.data
      })
    }
  },
}
</script>
