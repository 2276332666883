<template>
  <div style="width: 95%;margin: 10px auto">
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("meal_list") }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
        <v-autocomplete style="margin-bottom: -10px;margin-right: 15px"
          v-model="category_id"
          :items="categories"
          dense
          multiple
          :label="$t('group_meal1')"
          return-object
          :item-text="getItemText"
          item-value="id" @change="filterMealByCategory()">
        </v-autocomplete>
       <v-text-field
        class="pr-4 pb-2"
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search')"
        single-line
        hide-details
        dense
      ></v-text-field>
      <v-btn v-if="$store.state.restaurant.parent" style="font-size: 10px"
             :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform:unset'"
             dark
             class="mb-2 mr-2"
             small
             @click.prevent="dialogN = true"
      >
        {{ $t('cloneM') }} {{ $store.state.restaurant.parent ? translate($store.state.restaurant.parent.name) : '' }}
      </v-btn>
      <v-dialog v-model="dialogN" max-width="100%" scrollable style="margin: 0">
        <v-card>
          <v-card-title class="primary" style="display: flex;justify-content: space-between">
            <span class="headline white--text"> {{ $t('cloneM') }} {{ $store.state.restaurant.parent ? $store.state.restaurant.parent.name : '' }}</span>
            <a @click.prevent="dialogN = false" style="font-size: 14px !important; border: 1px solid #fff;padding: 1px 13px;border-radius: 19px" class="headline white--text">x</a>
          </v-card-title>
          <v-card-text>
            <v-text-field style="width: 50%;margin-left: auto"
                          class="py-2"
                          v-model="search1"
                          append-icon="mdi-magnify"
                          :label="$t('search')"
                          single-line
                          hide-details
                          dense
            ></v-text-field>
            <v-data-table dense :headers="headersN" :search="search1" class="grey lighten-4" style="height: 100%" :items="parentGroceries">
              <template v-for="header in headersN" v-slot:[`header.${header.value}`]="{ header }">
                      <span v-if="header.text == 'mark'">
                        <input id="checkbox_id" type="checkbox" v-model="select" @change.prevent="selectAll()">
                         <label for="checkbox_id">{{ $t('mark') }}</label>
                      </span>
                <span v-else>{{ $t(header.text) }}</span>
              </template>
              <template v-slot:item.actions="{ item, index }">
                <span v-if="! prepData[item.id]">
                  <input type="checkbox" v-model="item.selected">
                </span>
                <span v-else style="font-size: 13px;color: #9a9a9a">Klonirano</span>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary px-5" text @click="dialogN = false">
              {{ $t('quit') }}
            </v-btn>
            <v-btn style="border-radius: 10px" color="primary px-5" dark @click="saveClone">
              {{ translate('Клонирај') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        :style="'margin-top: -8px;border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
        small
        text
        class="mr-2"
        @click="dialogSizes = true"
      >
          <v-icon class="mr-2">mdi-view-quilt</v-icon>{{ $t("meal_size1") }}
      </v-btn>
      <v-btn
        :style="'margin-top: -8px;border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
        small
        text
        class="mr-2"
        @click="$router.push({ name: 'QRlist', params: { jela: idProps } })"
      >
        <v-icon class="mr-2">mdi-checkerboard</v-icon>{{ $t("qr") }}
      </v-btn>
      <v-dialog v-model="dialog" max-width="700px" scrollable persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
            dark
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            small
          >
          <v-icon>mdi-plus</v-icon>
            {{ $t("add_meals") }}
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="primary" style="display: flex;justify-content: space-between;background: rgb(251, 109, 59)">
            <span class="headline white--text"
              >{{ translate(formTitle) }} | ID: {{ editedItem.id }}</span
            >
            <v-btn color="primary px-5" text @click="close"><v-icon style="color: #fff">mdi-close</v-icon> </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.name"
                    :label="$t('*title')"
                  ></v-text-field>
                  <p class="greska" v-if="errorNaziv">{{ $t('name_field_mandatory') }}</p>
                </v-col>
                <v-row class="px-3">
                  <v-row class="px-3">
                    <v-col cols="6">
                      <v-text-field
                          v-model="editedItem.code"
                          :label="$t('item_code')"
                      ></v-text-field>
                      <p class="greska" v-if="errorSifra">{{ $t('password_field_mandatory') }}</p>
                    </v-col>

                    <v-col cols="4" class="">
                      <v-text-field
                          type="number"
                          v-model="editedItem.price"
                          :label="$t('basic_price')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" style="display: flex;align-items: center">
                      <p class="mb-0" style="font-weight: 600">{{ $store.state.restaurant.settings ? $store.state.restaurant.settings.price : '' }}</p>
                    </v-col>
                  </v-row>
<!--                  v-if="sizes.length > 0"-->
                  <div class="px-3"  style="width: 100%;margin-top: -9px">
                    <v-row class="px-1 mt-1">
                      <v-col cols="6" class="mt-4">
                        <v-autocomplete
                            v-model="editedItem.category"
                            :items="categories"
                            dense
                            :label="$t('group_meal')"
                            return-object
                            :item-text="getItemText"
                            item-value="id"
                        ></v-autocomplete>
                        <p class="greska" v-if="errorGrupa">{{ $t('group_of_meals_field_mandatory') }}</p>
                      </v-col>
                    </v-row>
                    <div v-if="editedItem.category && editedItem.category.main_group == 1">
                      <div style="display: flex;flex-wrap: wrap;width: 95%">
                      <label style="width: 65%" for="">{{ translate('Категорије из којих може бирати јела')}}</label>
                      <label style="width: 35%" for="">{{ translate('Дозвољена количина')}}</label>
                      </div>
                      <div v-for="(category, index) in kategorije"  :key="index"  style="display: flex;flex-wrap: wrap;width: 85%">
                        <div style="width: 80%">
                        <v-checkbox  style="width: 95%;"
                                    :key="index"
                                     v-bind:false-value="0"
                                     v-bind:true-value="1"
                                    :value="additional_food_new[index].active"
                                    v-model="additional_food_new[index].active"
                                    :label="translate(category.name)"
                                     @change="addToAdditionalCategories(index)"
                                    hide-details>
                        </v-checkbox>
                        </div>
                        <div v-if="additional_food_new[index].active" :key="index" style="width: 20%;padding: 2px" v-show="additional_food_new[index].active">
<!--                          <v-text-field :title="translate('Дозвољена количина')"-->
<!--                                        :label="translate('Дозвољена количина')"-->
<!--                                        type="number"-->
<!--                                        v-model.number="additional_food_new[index].choice_number">-->
<!--                          </v-text-field>-->
                          <input type="number" @keyup="addChoiceNumber(additional_food_new[index])" @blur="addChoiceNumber(additional_food_new[index])" :placeholder="translate('Дозвољена количина') + '...'" v-model="additional_food_new[index].choice_number" style="border-bottom: 1px solid #cfcfcf;padding: 2px;margin-top: 3px">
                        </div>
                      </div>
                    </div>
                    <h3 v-if="editedItem.price && editedItem.sizes.length > 0" class="mb-3">{{ $t('meal_size') }}</h3>
                    <div v-if="editedItem.price && editedItem.sizes.length > 0" class="py-1 d-flex" style="flex-wrap: wrap;align-items: center;">
                      <span style="width: 40%;font-weight: 700">{{ $t('title')}}</span>
                      <span style="width: 30%;font-weight: 700;padding-left: 20px">{{ $t('price')}} | {{ $store.state.restaurant && $store.state.restaurant.settings ? $store.state.restaurant.settings.price : '' }}</span>
                      <span style="width: 30%"></span>
                      <span></span>
                    </div>
                    <div v-if="editedItem.price && editedItem.sizes.length > 0" v-for="(size, index) in editedItem.sizes" class="py-1 d-flex" style="flex-wrap: wrap;align-items: center;border-bottom: 1px solid #cfcfcf">
                      <v-checkbox style="width: 48%;"
                        :key="index"
                        v-model="size.is_true"
                        :label="size.name"
                        @change="check($event)"
                        hide-details>
                      </v-checkbox>
                      <div style="display: flex;justify-content: space-between;align-items: center;width: 40%;">
                        <p class="my-0 py-0">{{ size.price_meal }}</p>
                        <span v-if="size.is_true">
                          <a v-if="size.price_meal" style="text-decoration: none" @click.prevent="prilogCena('edit', size)" href="">{{ $t('edit')}}</a>
                          <a v-else style="text-decoration: none;display: inline-block;margin-left: 30px" @click.prevent="prilogCena('add', size)" href="">
                            <span v-if="editedItem.sizes.length">{{ $t('add_price')}}</span>
                          </a>
                          <span style="margin-left: 5px" v-if="size.price_meal">|
                            <a title="Ukloni cenu" href="" @click.prevent="openDialogEditSizesPriceDelete(size)">
                              <v-icon style="font-size: 15px;color: #ee2727"> mdi-trash-can</v-icon>
                            </a>
                          </span>
                        </span>
                      </div>
                    </div>
                    <v-dialog v-model="dialogCena" max-width="300px" scrollable>
                      <v-card>
                        <v-card-title class="primary">
                          <span class="headline white--text">{{ $t('price') }}</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container style="padding-bottom: 10px !important;">
                            <input type="number" v-model="priceForAddon.price_meal" :placeholder="$t('price')" style="border: 1px solid #cfcfcf;width: 100%;padding: 6px 9px;border-radius: 6px">
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary px-5" text @click="dialogCena = false"> {{ $t("give_up") }} </v-btn>
                          <v-btn color="primary px-5" text @click.prevent="sacuvajCenu"> {{ $t("save") }} </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-row>
                <v-col cols="12" class="mt-3">
                  <v-textarea
                      rows="2"
                    v-model="editedItem.description"
                    :label="$t('description')"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                  <v-text-field
                      v-model="editedItem.note"
                      :label="$t('note')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <label for="">{{ $t('menu_type') }}</label>
                  <v-row>
                    <v-col style="padding: 7px 2px;margin: 2px 0" :key="index" cols="auto" v-for="(check, index) in meniType">
                      <v-checkbox multiple
                        v-model="editedItem.type"
                        :label=" $t(check.name)"
                        :value="check.id"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <span class="title">{{ $t("composition") }}:</span>
                </v-col>
                <v-col cols="12" class="mb-3" v-if="itemsGroceries.length == 0">{{  $t('no_groceries') }}</v-col>
                <v-col cols="12" v-if="itemsGroceries.length > 0">
                  <v-row
                    no-gutters
                    align="center"
                    jusstify="start"
                    class="pb-4"
                  >
                    <v-autocomplete
                      v-model="grocery"
                      :items="itemsGroceries"
                      dense
                      item-text="name"
                      item-value="id"
                      return-object
                      :label="$t('ingredients')"
                      hide-details
                    ></v-autocomplete>
                    <v-btn
                      color="primary"
                      text
                      @click="
                        grocery &&
                          editedItem.groceries.push({
                            ...grocery,
                            pivot: { quantity: 0 },
                          })
                      "
                      class="ml-2"
                    >
                      {{ $t('add') }}
                    </v-btn>
                  </v-row>
                  <v-row no-gutter class="my-1">
                    <v-data-table
                      :headers="[
                        {
                          text: 'Id',
                          align: 'start',
                          sortable: true,
                          value: 'id',
                        },
                        {
                          text: $t('title'),
                          align: 'start',
                          sortable: true,
                          value: 'name',
                        },
                        {
                          text: $t('quantity'),
                          value: 'pivot.quantity',
                          align: 'end',
                          sortable: true,
                        },
                        {
                          text: $t('q'),
                          value: 'measure',
                          align: 'start',
                          sortable: false,
                        },
                        {
                          text: $t('opt'),
                          value: 'actions',
                          align: 'end',
                          sortable: false,
                        },
                      ]"
                      :items="editedItem.groceries"
                      dense
                      hide-default-footer
                      :items-per-page="20"
                    >
                    <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }"  >
                        {{translate(header.text)}}
                    </template>
                    <template v-slot:header.name="{ header }">
                        {{translate(header.text)}}
                    </template>
                    <template v-slot:header.pivot.quantity="{ header }">
                        {{ translate(header.text)}}
                    </template>
                    <template v-slot:header.actions="{ header }">
                        {{translate(header.text)}}
                    </template>
                    <template v-slot:header.measure="{ header }">
                        {{ translate(header.text)}}
                    </template>
                    <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
                        {{translate(item[values.value])}}
                    </template>
                      <template v-slot:item.pivot.quantity="props">
                        <v-edit-dialog
                          :return-value.sync="props.item.pivot.quantity"
                        >
                          {{ props.item.pivot.quantity }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.pivot.quantity"
                              :label="$t('change')"
                              single-line
                              counter
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="removeGrocery(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-row>
                </v-col>
              </v-row>
              <div style="width: 100%;padding: 0;margin: 0">
                <v-col style="padding-left: 0;padding-right: 0" cols="12">
                  <span class="title">{{ $t("attachment_meal") }}:</span>
                </v-col>
                <v-col style="padding-left: 0;padding-right: 0" cols="12" >{{ $t('n_p') }}</v-col>
                <v-col style="padding-left: 0;padding-right: 0" cols="12">
                  <v-row
                      no-gutters
                      align="center"
                      jusstify="start"
                      class="pb-4"
                  >
                    <v-autocomplete
                        v-model="attach"
                        :items="attachmentM"
                        dense
                        :label="$t('attachment_meals')"
                        return-object
                        :item-text="getItemText"
                        item-value="id"
                    ></v-autocomplete>
                    <v-btn
                        color="primary"
                        text
                        style="border-radius: 12px"
                        @click="
                        attach &&
                          editedItem.prilog.push({
                            ...attach,
                            pivot: { quantity: 0 },
                          })
                      "
                        class="ml-2"
                    >
                      {{ $t('add') }}
                    </v-btn>
                  </v-row>
                  <v-row no-gutter class="my-1">
                    <v-data-table
                      :headers="[
                        {
                          text: 'Id',
                          align: 'start',
                          sortable: true,
                          value: 'id',
                        },
                        {
                          text: $t('title'),
                          align: 'start',
                          sortable: true,
                          value: 'name',
                        },
                        {
                          text: $t('price'),
                          value: 'price',
                          align: 'start',
                          sortable: true,
                        },
                        {
                          text: $t('opt'),
                          value: 'actions',
                          align: 'end',
                          sortable: false,
                        },
                      ]"
                      :items="editedItem.prilog"
                      dense
                      hide-default-footer
                      :items-per-page="20"
                    >
                      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }"  >
                        {{$t(header.text)}}
                      </template>
                      <template v-slot:header.name="{ header }">
                        {{translate(header.text)}}
                      </template>
                      <!--                        <template v-slot:header.pivot.quantity="{ header }">-->
                      <!--                          {{ translate(header.text)}}-->
                      <!--                        </template>-->
                      <template v-slot:header.actions="{ header }">
                        {{translate(header.text)}}
                      </template>
                      <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
                        {{translate(item[values.value])}}
                      </template>
                      <template v-slot:item.price="{item}">
                        {{ item.price }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="removePrilog(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-row>
                </v-col>
              </div>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn style="border-radius: 10px;background: rgb(251, 109, 59)" color="primary px-5" dark @click="save"> {{ $t("save") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSizes" max-width="600px" scrollable persistent>
        <v-card>
          <v-card-title class="primary" style="display: flex;justify-content: space-between;background: rgb(251, 109, 59)">
            <span class="headline white--text">
<!--              {{ $t('portion_size') }} -->
              {{ $t("meal_size1") }}
              </span>
            <v-btn color="primary px-5" text @click="dialogSizes = false"> <v-icon style="color: #fff">mdi-close</v-icon> </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container style="padding-bottom: 10px !important;">
              <v-row>
                <v-row class="px-3">
                  <v-col cols="9">
                    <v-text-field
                      v-model="new_size"
                      :label="$t('add_meal_size')"
                      single-line
                    ></v-text-field>
                    <p class="my-0" style="color: #d52626;margin-top: -17px !important;font-size: 11px" v-if="name_error">{{ $t('name')}} - {{ $t('mandatory_field')}} </p>
                  </v-col>
                  <v-col cols="3">
                    <v-btn style="border-radius: 10px;background: rgb(251, 109, 59)" color="primary px-5 mt-3" dark @click="saveSizes">{{ $t("add") }} </v-btn>
                  </v-col>
                </v-row>
                <v-col cols="12">
                  <h4>{{ $t('all_sizes') }}</h4>
                </v-col>
                <v-col cols="12">
                  <ul style="list-style: none;padding: 0">
                    <li :key="index" v-for="(size, index) in sizes" class="py-1 d-flex" style="justify-content: space-between;border-bottom: 1px solid #cfcfcf;flex-wrap: wrap">
                      <span style="width: 30%">{{ size.name }}</span>
                      <span style="width: 20%;text-align: right">{{ $t('align') }}: {{ size.poredak }}</span>
                      <span style="width: 50%;text-align: right">
                        <a style="text-decoration: none;font-size: 12px" href="" @click.prevent="openDialogEditSize(size)">{{ $t('edit') }}</a>
                        <v-divider vertical class="mx-2"></v-divider>
                        <a href="" @click.prevent="openDialogEditSizesDelete(size)"><v-icon style="font-size: 15px;color: #ee2727"> mdi-trash-can </v-icon></a>
                      </span>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDeleteSizePrice" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{$t("delete_confirmation")}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDeleteSizePrice"
                    >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >{{ $t('confirm') }}</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">{{$t("delete_confirmation")}}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{ $t('cancel') }}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >{{ $t('confirm') }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-dialog v-model="dialogEditSizesDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">{{$t("delete_confirmation")}}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue px-5 mt-3" text @click="closeDialogEditSize"
                >{{ $t('cancel') }}</v-btn
              >
              <v-btn color="primary px-5 mt-3" dark text @click="deleteSizeItemConfirm"
                >{{ $t('confirm') }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-dialog v-model="dialogEditSizesPriceDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{$t("delete_confirmation")}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue px-5 mt-3" text @click="dialogEditSizesPriceDelete = false"
                    >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn color="primary px-5 mt-3" dark text @click="editSizesPriceDeleteConfirm"
                    >{{ $t('confirm') }}</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEditSizes" max-width="500px">
        <v-card>
          <v-card-title class="headline pl-8" style="background: rgb(251, 109, 59);display: flex;justify-content: space-between">
            <span style="color: #fff">
              {{$t("edit")}} {{ $t("meal_size1") }}
            </span>
            <v-btn color="blue darken-1" text @click="closeDialogEditSize"><v-icon style="color: #fff">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container style="padding-bottom: 10px !important;padding-left: 0 !important;">
              <v-col cols="12">
                <label for="">{{ $t('title') }}:</label>
                <v-text-field
                  v-model="editSizes.name"
                  :label="$t('add_meal_size')"
                  single-line
                ></v-text-field>
              </v-col>
              <v-col cols="12">
<!--                <label for="">{{ $t('align') }}:</label>-->
                <v-text-field
                  v-model="editSizes.poredak"
                  :label="$t('align')"
                  single-line
                ></v-text-field>
              </v-col>
            </v-container>
            <v-card-actions>
              <div class="text-right" style="width: 100%">
                <v-btn color="primary" style="background: rgb(251, 109, 59)" text dark @click="editItemConfirm">{{ $t('confirm') }}</v-btn>
              </div>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      sort-by="calories"
      class="elevation-1"
      mobile-breakpoint="0"
      dense>
      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">{{ $t(header.text) }}</template>
      <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item, index }">
        <span v-if="values.value == 'for_sale'">
           <v-checkbox
               v-model="item[values.value]"
               @change="forSale(item, index)"
           ></v-checkbox>
        </span>
        <span v-else-if="values.value == 'image_id'">
          <span style="position: relative" class="row">
            <input style="cursor: pointer;position: relative;z-index: 999" type="file" id="upload" @change="upload(item)" />
            <span style="position: absolute;left: 5px;z-index: 0;top: -9px" v-if="! item.image"><v-icon style="font-size: 51px" small>mdi-image</v-icon></span>
            <label style="position: absolute;top: -16px" v-else class="ma-3" for="upload">
              <v-img height="40" width="40" v-if="item.image && item.image.name == 'no-image.png'" :src="item.image.url"></v-img>
              <v-img height="40" v-else width="40" :src="conf.apiUrlBase +'/gallery/' + $store.state.restaurant.id +'/medium/' + item.image.name"></v-img>
              <a style="position: absolute;top: -8px;right: -7px;background: #c50b0b;color: #fff;font-size: 10px;padding: 1px 5px;border-radius: 15px;text-decoration: none;" @click.prevent="removeImage(item)" href="" title="Ukloni sliku">X</a>
           </label>
          </span>
        </span>
        <span v-if="values.value != 'for_sale' && values.value != 'image_id'">
          {{ translate(item[values.value]) }}
        </span>
      </template>
      <template v-slot:item.category="{item}">
        {{ item && item.category ? translate(item.category.name) : ''}}
      </template>
      <template v-slot:item.sizes="{item}">
          {{ item.sizes && item.sizes.name ? translate(item.sizes.name) : '' }}
      </template>
      <template v-slot:item.name="{item}">
        {{ translate(item.name) }}
      </template>
      <template v-slot:item.groceries="{item}">
        <td class="text-body-2">
          <template v-for="grocery in item.groceries">
            {{grocery.name}}: {{grocery.pivot?.quantity}} {{grocery.measure}}<br>
          </template>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="close2(item)" class="px-2">mdi-qrcode-scan</v-icon>
        <v-icon small class="mr-2" @click="print(item)"> mdi-printer </v-icon>
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"  @click="initialize">{{ translate('Ресетујте') }}</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog2" max-width="500px" scrollable>
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">{{ $t("qr_code") }}</span>
          <v-spacer></v-spacer>
          <span class="headline white--text"
          >{{ translate(editedItem.name) + " ID" }}: {{ editedItem.id }}</span
          >
        </v-card-title>

        <v-card-text>
          <v-row justify="center" class="pt-5">
            <vue-qrcode
              :value="
                JSON.stringify({
                  id: this.selectedQr,
                  secure: this.selectedQrSecret,
                })
              "
              :options="{ width: 200 }"
              style="border: 2px solid gray"
            ></vue-qrcode>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close2">
            {{ $t("close") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  components: {
    VueQrcode,
  },
  data: () => ({
    category_id: '',
    idProp: '',
    conf: conf,
    attach: '',
    search: "",
    search1: "",
    dialog: false,
    dialogSizes: false,
    dialogEditSizes: false,
    dialogEditSizesDelete: false,
    dialogDeleteSizePrice: false,
    dialog2: false,
    dialogDelete: false,
    dialogCena: false,
    errorSifra: false,
    dialogN: false,
    errorNaziv: false,
    errorGrupa: false,
    name_error: false,
    select: false,
    new_size: '',
    headers: [
      { text: "id_pr", value: "id", sortable: true },
      { text: "image", align: "start", value: "image_id" },
      { text: "title", align: "start", value: "name",},
      // { text: "meal_size1", value: "sizes" },
      // { text: "code", value: "code" },
      { text: "price", value: "price" },
      { text: "group", value: "category" },
      { text: "normativ", value: "groceries" },
      { text: "s_v", value: "for_sale" },
      { text: "actions", value: "actions", sortable: false, width: "13%" },
    ],
    headersN: [
      { text: 'mark', align: 'center', value: 'actions', sortable: false },
      {text: 'title', align: 'start', value: 'name',},
      {text: 'id', align: 'start', value: 'id',},
      // {text: 'q', align: 'start', value: 'measure', sortable: false},
    ],
    desserts: [],
    originalDesserts: [],
    editedIndex: -1,
    attachment_meals: [],
    additional_food_new: [],
    editedItem: {
      name: "",
      description: "",
      note: "",
      category: "",
      sizes: [],
      cena: '',
      price: 0,
      groceries: [],
      code: null,
      type: [],
      prilog: [],
      attachments: [],
      selectedPrices: [],
      // additional_food: [{
      //   'food_id': '',
      //   'category_id': '',
      //   'choice_number': '',
      //   'restaurant_id': ''
      // }],
    },
    defaultItem: {
      name: "",
      description: "",
      note: "",
      category: "",
      sizes: [],
      cena: '',
      price: 0,
      groceries: [],
      code: null,
      prilog: [],
      type: [],
      attachments: [],
      selectedPrices: [],
      // additional_food: [{
      //   'food_id': '',
      //   'category_id': '',
      //   'choice_number': '',
      //   'restaurant_id': ''
      // }],
    },
    grocery: null,
    itemsGroceries: [],
    itemsGroceriesDefault: [],
    categories: [],
    sizes: [],
    selectedQrSecret: false,
    selectedQr: false,
    dialogEditSizesPriceDelete: false,
    editSizes: '',
    priceForAddon: '',
    new_price: '',
    editSizesPriceDelete: '',
    parentGroceries: '',
    meniType: '',
  }),

  computed: {
    kategorije() {
      let a = []
      this.categories.filter(item => {
        if (item.main_group != 1) {
          a.push(item)
        }
      })
      return a
    },
    idProps() {
        return this.idProp = this.$store.state.restaurant.id;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('n_m') : this.$t('i_m');
    },
    attachmentMeals() {
      let prilog = []
      this.desserts.filter(item => {
        if (item.prilog == 1) {
          prilog.push(item)
        }
      })
      return prilog
    },
    attachmentM() {
      let array = []
      if (this.editedItem.prilog && this.editedItem.prilog.length > 0) {
        this.editedItem.prilog.filter(prilog => {
          this.attachment_meals.filter(item => {
            if (prilog.id != item.id) {
              array.push(item)
            }
          })
        })
      }
      if (this.editedItem.prilog && this.editedItem.prilog.length == 0) {
        array = this.attachment_meals
      }
      return array
    },
      prepData() {
      let items = {}
      this.desserts.filter(item => {
        items[item.clone_food_id] = item.clone_food_id ? true : false
      })
      return items
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    idProp() {
      return this.$store.state.restaurant.id;
    },
  },

  created() {
    if (this.$store.state.restaurant.parent_id) {
      this.getParentMeals()
    }
    this.initialize();
    this.getCategories();
    this.getSizes();
    this.getPrilog();
    // axios.get('/restaurants/countries-all').then(({data}) => {
    //   console.log(data)
    // })
  },

  methods: {
    addChoiceNumber(number) {
      number.category_id = number.id
      number.food_id = this.editedItem.id
      axios.post('/foods/additional-foods-choice-number', number).then(({data}) => {
        this.editedItem.additional_food = data
      })
    },
    addToAdditionalCategories(index) {
      this.additional_food_new[index].category_id = this.additional_food_new[index].id
      if (this.additional_food_new[index].active) {
        this.editedItem.additional_food.push(this.additional_food_new[index])
        this.additional_food_new[index].food_id = this.editedItem.id
        axios.post('/foods/additional-foods', this.additional_food_new[index]).then(({data}) => {
          this.editedItem.additional_food = data
        })
      } else {
        this.editedItem.additional_food.filter((item, i) => {
          if (item.category_id == this.additional_food_new[index].category_id) {
            item.remove = true
            item.food_id = this.editedItem.id
            axios.post('/foods/additional-foods', item).then(({data}) => {
              this.editedItem.additional_food = data
            })
            this.editedItem.additional_food.splice(i, 1)
          }
        })
      }
      this.$forceUpdate()
    },
    selectAll() {
      if (this.select) {
        this.parentGroceries.filter(item => {
          item.selected = true
        })
      } else {
        this.parentGroceries.filter(item => {
          item.selected = false
        })
      }
    },
    saveClone() {
      let list = {}
      list['items'] = []
      list['restoran'] = this.$store.state.restaurant.id
      this.parentGroceries.filter(item => {
        if (item.selected) {
          item.restoran = this.$store.state.restaurant.id
          list['items'].push(item)
        }
      })
      axios.post('/foods/add-parent-foods', {'clone': list}).then(({data}) => {
        if(data) {
          this.initialize();
          this.getCategories();
          this.getSizes();
          this.getPrilog();
          this.dialogN = false
          this.search1 = ''
          this.$forceUpdate()
        }
      })
    },
    getParentMeals() {
      axios.post('/foods/get-parent-foods', {'restaurant': this.$store.state.restaurant.parent_id}).then(({data}) => {
        data.filter(item => {
          item.selected = false
        })
        this.parentGroceries = data
      })
    },
    editSizesPriceDeleteConfirm() {
      axios.post('/foods/sizes/size-price-delete', this.editSizesPriceDelete).then(({data}) => {
        this.getSizes()
        this.editedItem.sizes.filter((s, idn) => {
          if(s.price) {
            s.price.filter((p,index) => {
              if (p.id == this.editSizesPriceDelete.price_meal_id) {
                  s.is_true = false
                  s.price_meal = ''
              }
            });
          }
        })
        this.dialogEditSizesPriceDelete = false
        this.$forceUpdate()
      })
    },
    sacuvajCenu() {
      let data = {}
      data['podaci'] = this.priceForAddon
      data['meal'] = this.editedItem
      data['cena'] = this.new_price
      axios.post('/foods/sizes/new-price', data).then(({data}) => {
        if (this.editedItem.selectedPrices.length) {
          this.editedItem.selectedPrices.filter(selected => {
            if (selected.size_id == data.size_id && selected.meal_id == data.meal_id) {
              selected.price = data.price
            }
          })
        } else {
          this.editedItem.selectedPrices.push(data)
        }
        this.editedItem.sizes.filter(size => {
          if (size.id == data.size_id) {
            size.price_meal_id = data.id
            size.price.push(data)
          }
        })
        this.getSizes()
        this.dialogCena = false
        this.$forceUpdate()
      })
    },
    prilogCena(type, size) {
      this.dialogCena = true
      this.priceForAddon = size
      this.$forceUpdate()
    },
    check(event) {
        this.$forceUpdate()
    },
    closeDeleteSizePrice() {
        this.dialogDeleteSizePrice = false
        this.$forceUpdate()
    },
    deleteSizeItemConfirm() {
      if (this.editSizes) {
        axios.post('/foods/sizes-delete', this.editSizes).then(({data}) => {
          if(data) {
            this.dialogEditSizesDelete = false
            this.sizes = data
          }
        })
      }
    },
    openDialogEditSizesDelete(size) {
      this.dialogEditSizesDelete = true
      this.editSizes = size
    },
    openDialogEditSizesPriceDelete(size) {
      this.dialogEditSizesPriceDelete = true
      this.editSizesPriceDelete = size
    },
    closeDialogEditSize() {
      this.editSizes = ''
      this.dialogEditSizes = false
      this.dialogEditSizesDelete = false
      this.$forceUpdate()
    },
    editItemConfirm() {
      if (this.editSizes) {
        axios.post('/foods/sizes-edit', this.editSizes).then(({data}) => {
          if(data) {
            this.dialogEditSizes = false
          }
        })
      }
    },
    openDialogEditSize(size) {
      this.dialogEditSizes = true
      this.editSizes = size
    },
    saveSizes() {
      let data = {}
      this.name_error = false
      data['name'] = this.new_size
      data['restaurant_id'] = this.idProp
      if (this.new_size != '') {
        axios.post('/foods/sizes-new', data).then(({data}) => {
          if(data) {
            this.sizes = data
          }
        })
      } else {
        this.name_error = true
      }
    },
    filterMealByCategory() {
      let array = []
      let categories = []
      this.category_id.filter(it => {
        categories.push(it.id)
      })
      this.originalDesserts.filter(item => {
        if (categories.includes(item.category_id)) {
          array.push(item)
        }
      })
      if (this.category_id.length == 0) {
        this.desserts = this.originalDesserts
      } else {
        this.desserts = array
      }
    },
    removeImage(item) {
      axios.delete("/foods/food-image-delete/" + item.id).then((res) => {
        if (res.data) {
          this.desserts.filter(category => {
            if (category.id == item.id) {
              category.image_id = null
              category.image = null
            }
          })
        }
      });
    },
    getPrilog() {
      axios.get("/foods/prilog").then((res) => {
        this.attachment_meals = res.data
      });
    },
    getItemText(item) {
      if (item.prilog == 1) {
        return this.translate(`${item.name} (Прилог)`);
      } else {
        return this.translate(`${item.name}`);
      }
    },
    close2(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      axios.get(`/user/${this.editedItem.id}/hash`).then((res) => {
        this.selectedQrSecret = res.data;
        this.selectedQr = this.editedItem.id;
        this.dialog2 = !this.dialog2;
      });
    },
    upload(item) {
      let data = new FormData();
      data.append("image", event.target.files[0]);
      data.append("food_id", item.id);
      axios.post("/gallery", data).then((res) => {
        if (res.data) {
          this.desserts.filter(food => {
            if (food.id == item.id) {
              food.image_id = res.data.id
              food.image = res.data
            }
          })
        }
      });
    },
    forSale(item, index) {
      axios.post('/foods/set-daily-offer', {
        'food' : item,
        'id': item.id
      }).then(({data}) => {
        console.log(data)
      })
    },
    print(element) {
      //const prtHtml = document.getElementById(id).innerHTML;
      let prtHtml = '<h3>'+element.name+'</h3>'
      prtHtml += '<ul>'
      for(let i in element.groceries) {
        prtHtml += '<li>'+element.groceries[i].name+': ' + element.groceries[i].pivot.quantity + ' ' + element.groceries[i].measure+'</li>'
      }
      prtHtml += '</ul>'

      let stylesHtml = "";
      // for (const node of [
      //   ...document.querySelectorAll('link[rel="stylesheet"], style'),
      // ]) {
      //   stylesHtml += node.outerHTML;
      // }
      //console.log(stylesHtml);
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}

                                      </head>
                                      <body>
                                        ${prtHtml}
                                      </body>
                                    </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();

    },
    getCategories() {
      axios.get("/categories").then((res) => {
        this.categories = res.data;
      });
    },
    getSizes() {
      axios.get("/foods/sizes").then((res) => {
        this.sizes = res.data;
      });
    },
    initialize() {
      axios.get("/foods").then((res) => {
        this.desserts = res.data;
        this.originalDesserts = res.data;
        res.data.filter(item => {
          if (item.prilog == 1) {
            this.attachment_meals.push(item)
          }
        })
      });

      axios.get("/groceries").then((res) => {
        this.itemsGroceries = res.data;
        this.itemsGroceriesDefault = res.data;
      });

      axios.get("menus/menu-types").then((res) => {
        if (res.data) {
          this.meniType = res.data;
        }
      });
    },
    editItem(item) {
      this.additional_food_new = []
      this.categories.filter(cat => {
        if (cat.main_group != 1) {
          if(item.additional_food.length) {
            item.additional_food.filter(food => {
              if (food.category_id == cat.id) {
                cat.active = 1
                food.active = 1
                cat.choice_number = food.choice_number
              }
            })
          } else {
            cat.active = 0
            cat.choice_number = ''
          }

          this.additional_food_new.push(cat)
        }
      })
      if (item.menu_types && item.menu_types.length) {
        item.type = []
        item.menu_types.filter(menu => {
          item.type.push(menu.menu_type_id)
        })
      }
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // this.editedItem.additional_food = this.additional_food
      this.editedItem.sizes = this.sizes
      this.editedItem.selectedPrices = []
      this.sizes.filter(size => {
        size.is_true = false
        size.price_meal = ''
        if (size.price) {
          size.price.filter((p,index) => {
            if (p.meal_id == item.id) {
              size.is_true = true
              size.price_meal = p.price ? p.price : ''
              size.price_meal_id = p.id ? p.id : ''
              this.editedItem.selectedPrices.push({
                'name': size.name,
                'id': p.size_id,
                'price': p.price,
                'meal_id': p.meal_id,
                'size_id': p.size_id,
                'restaurant_id': p.restaurant_id,
              })
            }
          })
        }
      })
      this.$forceUpdate()
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let _editedIndex = this.editedIndex;
      axios.delete("/foods/" + this.editedItem.id).then((res) => {
        this.desserts.splice(_editedIndex, 1);
        this.closeDelete();
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.itemsGroceries = this.itemsGroceriesDefault;
        this.grocery = ''
        this.attach = ''
        this.editedIndex = -1;
        this.errorSifra = false
        this.errorNaziv = false
        this.errorGrupa = false
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.editedItem.category_id = this.editedItem.category.id;
      this.editedItem.sizes_id = this.editedItem.sizes.id;
      this.errorSifra = false
      this.errorNaziv = false
      this.errorGrupa = false
      let _editedItem = this.editedItem;
      let _editedIndex = this.editedIndex;
      if (this.editedIndex > -1) {
        axios
          .put("/foods/" + this.editedItem.id, this.editedItem)
          .then((res) => {
            Object.assign(this.desserts[_editedIndex], _editedItem);
            this.close();
          });
      } else {
          // if (this.editedItem.code == '' || this.editedItem.code == null) {
          //   this.errorSifra = true
          // }
        if (this.editedItem.name == '') {
          this.errorNaziv = true
        }
        if (this.editedItem.category == '') {
          this.errorGrupa = true
        }
        if (! this.errorSifra && ! this.errorNaziv && ! this.errorGrupa) {
          axios.post("/foods", this.editedItem).then((res) => {
            _editedItem.id = res.data.data.id;
            _editedItem.prilog = res.data.data.prilog;
            _editedItem.menu_types = res.data.data.menu_types;
            _editedItem.additional_food = [];
            this.desserts.push(_editedItem);
            this.getPrilog()
            if (_editedItem.prilog == 1) {
              this.attachment_meals.push(_editedItem)
            }
          });
          this.close();
        }
      }
    },
    removePrilog(item) {
      let b = this.editedItem.prilog.findIndex((i) => i.id === item.id);
      this.editedItem.prilog.splice(b, 1);
    },
    removeGrocery(item) {
      let a = this.editedItem.groceries.findIndex((i) => i.id === item.id);
      this.editedItem.groceries.splice(a, 1);
    },
  },
};
</script>

<style>
.v-data-table {
  width: 100% !important;
}
.greska {
  color: #c20606;
  font-size: 10px;
}
</style>
<style scoped>
::file-selector-button {
  display: none;
}
input[type='file'] {
  display: inline-block;
  width: 40px;
  padding: 30px 0 0 0;
  height: 30px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

}
</style>
