import { render, staticRenderFns } from "./Jela.vue?vue&type=template&id=52ac592c&scoped=true"
import script from "./Jela.vue?vue&type=script&lang=js"
export * from "./Jela.vue?vue&type=script&lang=js"
import style0 from "./Jela.vue?vue&type=style&index=0&id=52ac592c&prod&lang=css"
import style1 from "./Jela.vue?vue&type=style&index=1&id=52ac592c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ac592c",
  null
  
)

export default component.exports